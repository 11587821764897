import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [3,5,6,8,10];

export const dictionary = {
		"/": [~12],
		"/(backend)/admin": [~19,[3,4]],
		"/(backend)/admin/clients": [~20,[3,4]],
		"/(backend)/admin/users": [~21,[3,4]],
		"/(backend)/admin/users/create": [~23,[3,4]],
		"/(backend)/admin/users/[id]": [~22,[3,4]],
		"/(backend)/broker": [~24,[3]],
		"/(backend)/broker/benefits/[benefitId=objectid]/specs": [~26,[3,5]],
		"/(backend)/broker/benefits/[benefitId=objectid]/[planId=objectid]/[[section=number]]": [~25,[3,5]],
		"/(backend)/broker/clients": [~27,[3]],
		"/(backend)/broker/clients/new": [~34,[3]],
		"/(backend)/broker/clients/[clientId=objectid]": [~28,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits": [~29,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]": [30,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/edit": [~33,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources": [~31,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources/[resourceType=resourceType]": [~32,[3,6]],
		"/(backend)/broker/events": [~35,[3]],
		"/(backend)/broker/messages": [~36,[3]],
		"/(backend)/broker/messages/create": [~38,[3]],
		"/(backend)/broker/messages/[id]": [~37,[3]],
		"/(backend)/broker/profile": [~39,[3]],
		"/(backend)/broker/vendors": [~40,[3]],
		"/(backend)/broker/vendors/new": [~42,[3]],
		"/(backend)/broker/vendors/[id=objectid]": [~41,[3]],
		"/dev/presentation-test": [51,[11]],
		"/(auth)/forgot-password": [~13,[2]],
		"/(auth)/login": [~14,[2]],
		"/(auth)/logout": [~15,[2]],
		"/(backend)/(terms)/privacy": [~17,[3]],
		"/(auth)/reset-password/[token]": [~16,[2]],
		"/(backend)/(terms)/terms": [~18,[3]],
		"/(participants)/v/[benefitId=objectid]": [~43,[7]],
		"/(participants)/v/[slug]": [~44,[7,8]],
		"/(participants)/v/[slug]/[[code=code]]/authorize": [~49,[7,8,9]],
		"/(participants)/v/[slug]/[benefitId=objectid]": [~50,[7,8]],
		"/(participants)/v/[slug]/[[code=code]]/[period=benefitPeriod]": [~45,[7,8,9,10]],
		"/(participants)/v/[slug]/[[code=code]]/[period=benefitPeriod]/welcome": [~48,[7,8,9,10]],
		"/(participants)/v/[slug]/[[code=code]]/[period=benefitPeriod]/[section=benefitSection]/[[videoKey=videoKey]]": [~47,[7,8,9,10]],
		"/(participants)/v/[slug]/[[code=code]]/[period=benefitPeriod]/[messageType=messageType]/[[messageId=objectid]]": [~46,[7,8,9,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';